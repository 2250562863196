<template>
    <!--begin::Card-->
    <div class="card card-custom card-stretch">
        <!--begin::Header-->
        <div class="card-header py-3">
            <div class="card-title align-items-start flex-column">
                <h3 class="card-label font-weight-bolder text-dark">
                    Departamentele utilizatorului
                </h3>
                <span class="text-muted font-weight-bold font-size-sm mt-1"
                    >Modifica departamentele din care face parte utilizatorul</span
                >
            </div>
            <div class="card-toolbar">
                <button class="btn btn-success mr-2" @click="onSubmit">Salveaza</button>
                <button type="reset" class="btn btn-secondary" @click="onReset">
                    Reset
                </button>
            </div>
        </div>
        <!--end::Header-->
        <!--begin::Form-->
        <form class="form">
            <!--begin::Body-->
            <div class="card-body">
                <div class="card-body" v-if="userDepartments">
                    <!-- <div class="row">
                        <label class="col-xl-3"></label>
                        <div class="col-lg-9 col-xl-6">
                            <h5 class="font-weight-bold mb-6">
                                Setup Email Notification:
                            </h5>
                        </div>
                    </div> -->
                    <div class="form-group row align-items-center">
                        <label
                            class="col-xl-4 col-lg-4 col-form-label text-left text-lg-right"
                            >Departament</label
                        >
                        <div class="col-lg-2 col-xl-1"></div>
                        <div class="col-lg-2 col-xl-2">
                            <span class="switch switch-sm text-left text-lg-right">
                                <label> are Access </label>
                            </span>
                        </div>
                        <div class="col-lg-2 col-xl-2">
                            <span class="switch switch-sm">
                                <label> e Manager </label>
                            </span>
                        </div>
                    </div>
                    <template v-for="(department, i) in getDepartaments">
                        <div class="form-group row align-items-center" :key="i">
                            <label
                                class="col-xl-4 col-lg-4 col-form-label font-weight-bold text-left text-lg-right"
                                >{{ department.name }} <br /><span
                                    class="text-muted font-weight-light"
                                    >{{ department.email }}</span
                                >
                            </label>
                            <div class="col-lg-2 col-xl-1"></div>
                            <div class="col-lg-2 col-xl-2">
                                <span class="switch switch-sm text-left text-lg-right">
                                    <label>
                                        <input
                                            type="checkbox"
                                            v-model="form.mailboxes[department.id]"
                                            name="mailboxes"
                                            @change="changeMailbox"
                                        />
                                        <span></span>
                                    </label>
                                </span>
                            </div>
                            <div class="col-lg-2 col-xl-2">
                                <span class="switch switch-sm">
                                    <label>
                                        <input
                                            type="checkbox"
                                            v-model="form.isManager[department.id]"
                                            name="email_notification_1"
                                        />
                                        <span></span>
                                    </label>
                                </span>
                            </div>
                        </div>
                    </template>
                    <div v-if="departments" class="form-group row align-items-center">
                        <label
                            class="col-xl-4 col-lg-4 col-form-label font-weight-bold text-left text-lg-right"
                            ><span>Departament principal</span>
                        </label>
                        <div class="col-lg-2 col-xl-1"></div>
                        <div class="col-lg-4 col-xl-5">
                            <v-select
                                v-model="mainDepart"
                                :items="options"
                                menu-props="auto"
                                dense
                                outlined
                                hide-details
                            >
                                <template v-slot:item="{ active, item, attrs, on }">
                                    <v-list-item
                                        v-on="on"
                                        v-bind="attrs"
                                        #default="{ active }"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title>
                                                <v-row no-gutters align="center">
                                                    <span>{{ item.text }}</span>
                                                </v-row>
                                            </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </template>
                            </v-select>
                        </div>
                    </div>
                </div>
            </div>
            <!--end::Body-->
        </form>
        <!--end::Form-->
    </div>
    <!--end::Card-->
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
    name: "UsersEditProfileCard",
    props: ["currentUser"],
    components: {},
    data() {
        return {
            form: {
                mailboxes: {},
                isManager: {},
            },
            mainDepart: null,
            options: [{ value: null, text: "Alege un departament*", disabled: true }],
        };
    },
    mounted() {
        this.onReset();
        this.mainDepart = {
            value: this.currentUser.defaultMailbox.id,
            text: this.currentUser.defaultMailbox.name,
        };
    },
    computed: {
        getDepartaments() {
            if (this.user.permissions.can_manage_all_users) {
                return this.allDepartments;
            } else {
                return this.departments;
            }
        },
        ...mapGetters(["user"]),
        ...mapGetters("departments", [
            "userDepartments",
            ,
            "allDepartments",
            "eachDepartments",
            "departments",
        ]),
    },
    watch: {},
    methods: {
        ...mapActions("departments", [
            "getDepartmentsForUser",
            "getDepartments",
            "getPasareDepartments",
        ]),
        changeMailbox() {
            this.options = [
                { value: null, text: "Alege un departament*", disabled: true },
            ];
            this.allDepartments.forEach((dep) => {
                if (this.form.mailboxes[dep.id]) {
                    this.options.push({ value: dep.id, text: dep.name });
                }
            });
        },

        onSubmit(event) {
            event.preventDefault();
            var mailboxes = [];
            Object.keys(this.form.mailboxes).forEach((value) => {
                if (this.form.mailboxes[value]) {
                    mailboxes.push(value);
                }
            });
            var isManager = [];
            Object.keys(this.form.isManager).forEach((value) => {
                if (this.form.isManager[value]) {
                    isManager.push(value);
                }
            });
            let userId = this.currentUser.id;
            let data = {
                first_name: this.currentUser.firstName,
                last_name: this.currentUser.lastName,
                mailboxes: mailboxes,
                is_manager: isManager,
                status: this.currentUser.status,
                default_mailbox: this.mainDepart,
            };
            this.$store.dispatch("users/editUser", { userId, data });
        },
        onReset() {
            this.getDepartments();
            this.getPasareDepartments();
            this.getDepartmentsForUser(this.$route.params.userId)
                .then(() => {
                    this.form.isManager = {};
                    this.form.mailboxes = {};
                    this.userDepartments.forEach((element) => {
                        this.form.mailboxes[element.id] = true;
                        if (element.isManager) {
                            this.form.isManager[element.id] = true;
                        }
                    });
                })
                .then(() => {
                    this.changeMailbox();
                });
        },
    },
};
</script>
